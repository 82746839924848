import React from "react";

import { apps } from "../data/apps.js";
import logo from "../logo.png";

import { Box, Center, HStack, Heading, Image } from "native-base";
import Cards from "../components/Cards";

const App = () => {
  return (
    <Center pt={20}>
      <HStack alignItems="center" space="3" mb={4}>
        <Image source={logo} alt="logo" size="xs" w="90px" h="70px" />
        <Heading>PINNACLE LIFE APPLICATIONS HUB</Heading>
        {/* <Heading>DlA HUB</Heading> */}
        {/* <Image source={logo} alt="logo" size="xs" /> */}
      </HStack>

      <HStack flexWrap="wrap" justifyContent="center">
        {apps.map((item) => (
          <Cards
            name={item.name}
            content={item.content}
            icon={item.icon}
            onLink={() => window.open(item.link, "_blank")}
          />
        ))}
      </HStack>

      <Box mb={10} />
    </Center>
  );
};

export default App;
